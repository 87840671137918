<template>
  <div class="inter-wrapper">
    <HeaderInter back-path="/" />
    <div class="voucherList inter-content container-fluid pb-5">
      <div class="state-group">
        <div :class="['state-item', { 'active': selectedMode==='non-used' } ]" @click="setMode('non-used')">
          未使用
        </div>
        <div :class="['state-item', { 'active': selectedMode==='used' } ]" @click="setMode('used')">
          已使用
        </div>
        <div :class="['state-item', { 'active': selectedMode==='expired' } ]" @click="setMode('expired')">
          已作廢
        </div>
      </div>
      <InterNav @setCate="setCate" />
      <TicketList v-if="items" :items="items" :selected-cat-id="selectedCatId" />

      <scroll-loader :loader-method="getItems" :loader-disable="!enableAutoLoad" />
    </div>
  </div>
</template>

<script>
import HeaderInter from '@/components/HeaderInter'
import InterNav from '@/components/InterNav'
import TicketList from '@/components/TicketList'
import ScrollLoader from 'vue-scroll-loader/src/components/Scroll-Loader'
import { getVoucherList } from '@/api/voucher'

export default {
  name: 'Voucher',
  components: {
    HeaderInter,
    InterNav,
    TicketList,
    ScrollLoader
  },
  data() {
    return {
      items: [],
      page: 1,
      enableAutoLoad: false,
      selectedCatId: 0,
      selectedMode: this.$route.params.mode ? this.$route.params.mode : 'non-used' // 'non-used' // non-used 未使用 used 已使用 expired 已過期或已作廢
    }
  },
  created() {
    if (this.$route.params.cate) {
      this.setCate(parseInt(this.$route.params.cate, 10))
    } else {
      this.getItems()
    }
  },
  destroyed() {
    this.enableAutoLoad = false
  },
  mounted() {},
  methods: {
    getItems() {
      const data = {
        page: this.page++,
        paginate: process.env.VUE_APP_PAGINATE_SIZE,
        mode: this.selectedMode
      }
      if (this.selectedCatId !== 0) {
        data.category_id = this.selectedCatId
      }

      this.enableAutoLoad = false
      getVoucherList(data).then((response) => {
        this.items = this.items ? this.items : []
        this.items = [...this.items, ...response.data.data]

        this.enableAutoLoad = response.data.data.length >= process.env.VUE_APP_PAGINATE_SIZE
      })
    },
    setMode(mode) {
      this.enableAutoLoad = true
      this.selectedMode = mode
      this.page = 1
      this.items = false
      this.getItems()
    },
    setCate(cate_id) {
      this.enableAutoLoad = true
      this.selectedCatId = parseInt(cate_id)
      this.page = 1
      this.items = false
      this.getItems()
    }
  }
}
</script>
<style scoped lang="scss">
@import "assets/scss/common.scss";
.state-group {
  position: relative;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  background-color: rgba($main-color, $alpha: .35);
  border-radius: 100px;
  .state-item {
    flex: 0 0 calc(33.333333%);
    margin: 0;
    padding: 5px;
    font-size: 1rem;
    letter-spacing: 2px;
    text-align: center;
    color: $font-color;
    text-decoration: none;
    border: none;
    color: $white-color;
    cursor: pointer;
    transition: all 0.5s;
  }
  .state-item.active {
    background-color: rgba($main-color, $alpha: 1);
    border-radius: 55px;
  }
  .state-item:hover,
  .state-item:focus,
  .state-item:active {
    background-color: rgba($main-color, $alpha: 1);
    border-radius: 55px;
  }
}
.state-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .state-items {
    cursor: pointer;
    flex: 0 0 33.33333%;
  }
}
</style>

<template>
  <div class="ticketlist-area">
    <tabs
      :tabs="tabs"
      :current-tab="currentTab"
      :wrapper-class="'default-tabs'"
      :tab-class="'default-tabs__item'"
      @onClick="handleClick"
    />
    <div v-if="items.length > 0" class="content">
      <div v-if="currentTab === 'tab1'">
        <div class="ticket-tile">
          <div v-for="(item, index) in items" :key="index" class="ticket-items">
            <a @click="toItemDetail(item)">
              <div class="pic">
                <div
                  v-if="!item.validdate_end && !item.validdate_start"
                  :class="['date']"
                >
                  使用期限請參考商品內容
                </div>
                <div
                  v-else-if="checkvaliddate(item.validdate_end)"
                  :class="['date']"
                >
                  無期限
                </div>
                <div v-else :class="['date']">
                  有效日期<br>{{ item.validdate_start.substring(0, 10) }}至{{
                    item.validdate_end.substring(0, 10)
                  }}
                </div>
                <div
                  class="img"
                  :style="
                    'background: -webkit-gradient(linear, left top, right top, color-stop(85%, rgba(84, 84, 84, 0)), color-stop(85%, #545454)), url(' +
                      item.product.cover_image +
                      ') no-repeat center center;'
                  "
                >
                  <div class="circle" />
                </div>
              </div>
              <div class="info-box">
                <div class="main-title">{{ item.item_name }}</div>
                <div class="sub-title">
                  <span>{{ item.plan_name }}{{ item.price_name | pricename }}</span>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>

      <div v-if="currentTab === 'tab2'">
        <div class="ticket-list">
          <div
            v-for="(item, index) in items"
            :key="index"
            :class="['ticket-items', getUsageFailClass(item.status)]"
          >
            <a @click="toItemDetail(item)">
              <div :class="['overlay', getUsageClass(item.status)]" />
              <div class="circle" />
              <div class="main-title">{{ item.item_name }}</div>
              <div class="sub-title">
                <span>{{ item.plan_name }}{{ item.price_name | pricename }}</span>
              </div>
              <div
                v-if="!item.validdate_end && !item.validdate_start"
                :class="['date']"
              >
                使用期限請參考商品內容
              </div>
              <div
                v-else-if="checkvaliddate(item.validdate_end)"
                :class="['date']"
              >
                無期限
              </div>
              <div v-else :class="['date']">
                有效日期 {{ item.validdate_start.substring(0, 10) }}至{{
                  item.validdate_end.substring(0, 10)
                }}
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <p class="no_data">
        查無資料
      </p>
    </div>
  </div>
</template>

<script>
import Tabs from 'vue-tabs-with-active-line'
import { setCateClass } from '@/utils/tool'

const TABS = [
  {
    title: '區塊',
    value: 'tab1'
  },
  {
    title: '列表',
    value: 'tab2'
  }
]
export default {
  components: {
    Tabs
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    selectedCatId: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    tabs: TABS,
    currentTab: 'tab1'
  }),
  methods: {
    checkvaliddate(date) {
      if (date && new Date(date).getFullYear() === 2099) {
        return true
      } else return false
    },
    handleClick(newTab) {
      this.currentTab = newTab
    },
    setCateClass(cate_id) {
      return setCateClass(cate_id)
    },
    toItemDetail(item) {
      this.$router.push(
        '/voucher/detail/' + item.voucher_num + '/' + this.selectedCatId
      )
    },
    getUsageFailClass(type) {
      // REDEEM已使用 READY 準備中 DONE 已取得憑証 CANCEL 作廢
      switch (type) {
        case 'READY':
          return ''
        case 'DONE':
          return ''
        default:
          return 'fail'
      }
    },
    getUsageClass(type) {
      // REDEEM 已使用 READY 準備中 DONE 已取得憑証 CANCEL 作廢
      switch (type) {
        case 'REDEEM':
          return 'used'
        case 'CANCEL':
          return 'void'
        default:
          return ''
      }
    }
  }
}
</script>

<style lang="scss">
@import 'assets/scss/common.scss';
.ticketlist-area {
  max-width: 100%;
  width: 100%;
  margin: auto;
  position: relative;
}
.ticketlist-area .default-tabs {
  position: relative;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;

  .default-tabs__item {
    flex: 0 0 auto;
    margin: 0 0px 8px 35px;
    padding: 0;
    text-decoration: none;
    border: none;
    color: rgba($white-color, $alpha: 0);
    background-color: $white-color;
    cursor: pointer;
    font-size: 10px;
  }

  .default-tabs__item:nth-child(1) {
    background: url(~@/assets/images/icon-tile.svg) no-repeat center center;
    background-size: cover;
    margin: 0 -15px 8px 35px;
  }
  .default-tabs__item:nth-child(2) {
    background: url(~@/assets/images/icon-list.svg) no-repeat center center;
    background-size: cover;
  }
}

.ticket-tile {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .ticket-items {
    flex: 0 0 calc(50% - 8px);
    padding-bottom: 1rem;
    .info-box {
      .main-title {
        font-size: 1rem;
        line-height: 1.15rem;
        margin: 5px 0 5px 0;
        color: rgba($font-color, $alpha: 1);

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        height: 1.15rem;
      }
      .sub-title {
        font-size: 0.85rem;
        line-height: 1.15rem;
        color: rgba($font-color, $alpha: 1);

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        height: 2.35rem;
      }
    }
    .pic {
      position: relative;
      overflow: hidden;
      .circle {
        width: 20px;
        height: 20px;
        background: rgba($white-color, $alpha: 1);
        position: absolute;
        border-radius: 50%;
        top: calc(50% - 10px);
        right: -10px;
      }
      .date {
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        color: rgba($white-color, $alpha: 1);
        font-size: 0.7rem;
        line-height: 12px;
        padding: 6px 8px;
        border-radius: 0 0 10px 10px;
        z-index: 9;
        background: linear-gradient(
          0deg,
          rgba($sec-color, $alpha: 0.75) 0%,
          rgba($sec-color, $alpha: 0.75) 100%
        );
      }
      .img {
        background: linear-gradient(
            90deg,
            rgba($sec-color, $alpha: 0) 85%,
            rgba($sec-color, $alpha: 1) 85%
          ),
          url(~@/assets/images/1.jpg) no-repeat center center;
        background-size: auto, cover;
        // -webkit-mask: url(~@/assets/images/svg-ticket.svg) no-repeat center top !important;
        // mask: url(~@/assets/images/svg-ticket.svg) no-repeat center top !important;
        max-width: 100%;
        width: 100%;
        height: 125px;
        border-radius: 10px;
      }
    }
  }
}

.ticket-list {
  padding-bottom: 1.5rem;
  .ticket-items {
    border-radius: 10px;
    border: 1px solid rgba($main-color, $alpha: 1);
    height: 90px;
    // background: url(~@/assets/images/bg-ticketb.svg) no-repeat right top,
    //   url(~@/assets/images/bg-ticket.svg) no-repeat center top;
    // background-size: contain;
    background: linear-gradient(
      90deg,
      rgba($main-color, $alpha: 0) 92.5%,
      rgba($main-color, $alpha: 1) 92.5%
    );
    background-repeat: no-repeat;
    z-index: 2;
    position: relative;
    padding: 10px 0 0;
    margin-bottom: 1.25rem;
    border-radius: 10px;
    .main-title {
      font-size: 1rem;
      line-height: 1rem !important;
      margin: 0;
      padding: 0px;
      color: rgba($font-color, $alpha: 1);

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      height: 1rem;
      width: 92%;
    }
    .sub-title {
      font-size: 0.85rem;
      line-height: 1.15rem !important;
      color: rgba($font-color, $alpha: 1);
      padding: 0px 0 0 15px;
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        height: 1.15rem;
        width: 92.5%;
      }
    }
    .circle {
      width: 16px;
      height: 16px;
      background: rgba($white-color, $alpha: 1);
      position: absolute;
      border-radius: 50%;
      top: calc(50% - 8px);
      right: -8px;
    }
    .date {
      position: absolute;
      bottom: 0;
      z-index: 9;
      color: rgba($font-color, $alpha: 1);
      font-size: 0.7rem;
      padding: 0 0 0 15px;
      border-top: 1px solid rgba($main-color, $alpha: 1);
      max-width: 100%;
      width: 100%;
      height: 30px;
      line-height: 25px;
      letter-spacing: 1px;
    }
    .overlay.void {
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      z-index: 10;
      background: rgba($black-color, $alpha: 0.1);
      background-size: contain;
      -webkit-mask: url(~@/assets/images/icon-sold-a.svg) no-repeat
        calc(100% - 5px) top !important;
      mask: url(~@/assets/images/icon-sold-a.svg) no-repeat calc(100% - 5px) top !important;
    }
    .overlay.used {
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      z-index: 10;
      background: rgba($black-color, $alpha: 0.1);
      background-size: contain;
      -webkit-mask: url(~@/assets/images/icon-sold-b.svg) no-repeat
        calc(100% - 5px) top !important;
      mask: url(~@/assets/images/icon-sold-b.svg) no-repeat calc(100% - 5px) top !important;
    }
  }

  .ticket-items.fail {
    // background: url(~@/assets/images/bg-ticketb-g.svg) no-repeat right top,
    //   url(~@/assets/images/bg-ticket-g.svg) no-repeat center top;
    // background-size: contain;
    border: 1px solid rgba($font-color, $alpha: 0.25);
    background: linear-gradient(
      90deg,
      rgba($font-color, $alpha: 0) 92.5%,
      rgba($font-color, $alpha: 0.5) 92.5%
    );
    background-repeat: no-repeat;
    .main-title,
    .sub-title {
      color: rgba($black-color, $alpha: 0.5) !important;
    }
    .date {
      color: rgba($black-color, $alpha: 0.5);
      border-top: 1px solid rgba($font-color, $alpha: 0.25);
    }
  }
}

@media screen and (min-width: 768px) {
  .ticketlist-area {
    max-width: 100%;
    width: 100%;
    margin: auto;

    .ticket-tile {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-bottom: 1.5rem;
      .ticket-items {
        // flex: 0 0 calc(33.3333% - 8px);
        // margin-bottom: 1.25rem;
        .pic {
          .img {
            height: 210px;
          }
        }
      }
    }

    .ticket-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      .ticket-items {
        flex: 0 0 calc(50% - 5px);
        height: 78px;
        .main-title {
          font-size: 0.85rem;
          margin: 0;
          padding: 0px 0 2px 15px;
          color: rgba($font-color, $alpha: 1);
          line-height: 15px;
        }
        .sub-title {
          font-size: 0.85rem;
          color: rgba($font-color, $alpha: 1);
          padding: 0px 0 0 15px;
          line-height: 15px;
        }
        .date {
          max-width: 92.5%;
          width: 92.5%;
          left: auto;
        }
      }
    }
  }
}
</style>
